<template>
  <div :style="{marginTop: headerHeight}">
  <page :data="pageData" :is-child="_isChild" @refresh="refresh">
    <template #cmslefttype(vaddress-listitem)="{data: d}">
      <list-item
        :data="d.Data"
        @title-action-click="edit">
      </list-item>
    </template>

    <template #aftercmsleftcomponents>
      <modal-form-page class="modal-bg-gray" v-bind="modalProps('form')" @close="close">
      </modal-form-page>
    </template>
  </page>
  </div>
</template>

<script>
import ModalFormPage from '../base/ModalFormPage'
import CMSPage from '../base/CMSPage'
import { HEADER_HEIGHT } from '../../../layouts/vaddress/Main/Header.vue'
export default {
  extends: CMSPage,
  components: {
    ModalFormPage,
    ListItem: () => import('@/components/vaddress/ListItem')
  },
  methods: {
    edit (d) {
      const url = '/user/edit'
      this.openModal('form', { url })
    },
    close () {
    this.closeModal('form')
      this.init()
    }
  },
  computed: {
    headerHeight () {
      return HEADER_HEIGHT
    }
  },
  watch: {
    $route (v, o) {
      if (o.path === '/user/edit') {
        this.init()
      }
    }
  }
}
</script>

<style>

</style>
